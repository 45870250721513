import React, { Component } from "react";
import { Link } from "react-router-dom";

import imgLogo from "../assets/Logo.svg";

class SiteHeader extends Component {
  state = {};
  render() {
    return (
      <header>
        <div className="header--logo">
          <Link to="/">
            <img
              id="logo"
              src={imgLogo}
              alt="Logo von tom - its's fair design!"
            />
          </Link>
        </div>
      </header>
    );
  }
}

export default SiteHeader;
