import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";

import "./sass/main.scss";

import SiteHeader from "./components/siteHeader";
import SiteHome from "./components/siteHome";
import SiteFooter from "./components/siteFooter";
import SiteProjects from "./components/siteProjects";
import SiteDatenschutz from "./components/siteDatenschutz";

import getApiData from "./components/common/getApiData";

class App extends Component {
  state = {
    media: {
      breakPoints: {
        small: 414,
        medium: 960
      },
      viewPortWidth: window.innerWidth,
      size: "small"
    },
    address: {
      name: "tom - it's fair design",
      street: "Landskronweg 26",
      city: "4107 Ettingen",
      mail: "mail@tom-ifd.ch",
      tel: "+41 61 731 22 26"
    },
    projects: [],
    api: {
      key: "Bearer 05ed810d99d1f2e86117fc9706a921",
      rootUrl: "https://tom-ifd.ch",
      apiPath: "/cockpit-cms/api/"
    }
  };

  setMediaSize = () => {
    let newState = this.state.media;

    newState.viewPortWidth = window.innerWidth;

    if (newState.viewPortWidth > newState.breakPoints.medium) {
      newState.size = "large";
    }

    if (
      newState.viewPortWidth > newState.breakPoints.small &&
      newState.viewPortWidth <= newState.breakPoints.medium
    ) {
      newState.size = "medium";
    }

    if (newState.viewPortWidth <= newState.breakPoints.small) {
      newState.size = "small";
    }

    this.setState({ media: newState });
  };

  componentDidMount = async () => {
    let newState = await getApiData(this.state, "home");

    this.setState(newState);

    this.setMediaSize();
    window.addEventListener("resize", () => {
      this.setMediaSize();
    });
  };

  render() {
    return (
      <React.Fragment>
        <SiteHeader />
        <Routes>
          <Route
            exact
            path="/"
            element={(
              <SiteHome
                className="main__home"
                clientData={this.state.projects}
                mediaQueries={this.state.media}
                rootUrl={this.state.api.rootUrl}
              />
            )}
          />
          <Route
            path="/projekte"
            element={(
              <SiteProjects
                className="main__projects"
                projects={this.state.projects}
                rootUrl={this.state.api.rootUrl}
              />
            )}
          />
          <Route
            path="/datenschutz"
            element={(
              <SiteDatenschutz
                className="main__datenschutz"
                mediaQueries={this.state.media}
              />
            )}
          />          
        </Routes>
        <SiteFooter
          address={this.state.address}
          mediaQueries={this.state.media}
        />
      </React.Fragment>
    );
  }
}

export default App;
