import React from "react";

import imgClicker from "../../assets/Pfeil_rechts.svg";
import imgClickerHover from "../../assets/Pfeil_rechts_Hover.svg";

const ProjectsNavigation = ({ clientData, onChangeClient }) => {
  let output = clientData ? (
    <div>
      <div className="client--icons">
        <img
          src={imgClicker}
          alt="Clicker nach Links"
          onClick={() => onChangeClient(0)}
          onMouseOver={e => (e.target.src = imgClickerHover)}
          onMouseOut={e => (e.target.src = imgClicker)}
        />
      </div>

      <div className="client--data">
        <p>
          <span>Kunde</span>
          {clientData.name}
        </p>
        <p>
          <span>Arbeiten</span>
          {clientData.text}
        </p>
      </div>
      <div className="client--icons">
        <img
          src={imgClicker}
          alt="Clicker nach Rechts"
          direction="1"
          onClick={() => onChangeClient(1)}
          onMouseOver={e => (e.target.src = imgClickerHover)}
          onMouseOut={e => (e.target.src = imgClicker)}
        />
      </div>
    </div>
  ) : null;

  return output;
};

export default ProjectsNavigation;
