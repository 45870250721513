import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Kontakt from "./footer/kontakt";
import Address from "./footer/address";
import StoererTeaserContainer from "./footer/stoererTeaserContainer";

class SiteFooter extends Component {
  state = {
    stoererShow: false,
  };

  // Use functional setState to update the state based on the previous state
  handleShowStoerer = (show) => {
    this.setState({ stoererShow: show });
  };

  render() {
    const { mediaQueries, address } = this.props;

    return (
      <React.Fragment>
        <footer>
          <Kontakt className="footer__kontakt" mediaQueries={mediaQueries} />
          <div className="datenschutz">
            <Link to="/datenschutz">Datenschutzerklärung</Link>
          </div>
          <Address
            className="footer__adresse"
            mediaQueries={mediaQueries}
            address={address}
            handleShowStoerer={this.handleShowStoerer}
          />
          <StoererTeaserContainer
            className="footer__stoerer--container"
            handleShowStoerer={this.handleShowStoerer}
            stoererShow={this.state.stoererShow}
          />
        </footer>
      </React.Fragment>
    );
  }
}

export default SiteFooter;
