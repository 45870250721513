import React from "react";

import Banner from "./home/banner";
import Teaser from "./home/teaser";
import Projekte from "./home/projects";
import Spektrum from "./home/spektrum";
import Strategie from "./home/strategie";

const siteHome = ({ clientData, mediaQueries, rootUrl }) => {
  return (
    <main>
      <Banner className="main__banner" mediaQueries={mediaQueries} />
      <Teaser className="main__teaser" />
      <Projekte
        className="main__projekte"
        clientData={clientData}
        rootUrl={rootUrl}
      />
      <Spektrum className="main__spektrum" />
      <Strategie className="main__strategie" mediaQueries={mediaQueries} />
    </main>
  );
};

export default siteHome;
