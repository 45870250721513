import React, { forwardRef } from "react";

const Client = forwardRef(({ className, clientData, style, rootUrl }, ref) => {
  let output = clientData ? (
    // Use the forwarded ref here on the outer div
    <div className={className} style={style} ref={ref}>
      {clientData.gallery.map((img, index) => (
        <img
          key={index}
          src={`${rootUrl}${img.path}`}
          alt={img.meta.title !== "" ? img.meta.title : `Kundenbild ${index}`}
        />
      ))}
    </div>
  ) : null;

  return output;
});

export default Client;