import React from "react";

import imgStoerer from "../../assets/seit2008.svg";

const Teaser = ({ className }) => {
  return (
    <div className={className}>
      <div className={`${className}--stoerer`}>
        <img src={imgStoerer} alt="Störer mit Text: seit 2008" />
      </div>
      <p>
        <span>Wir gestalten!</span>Ihre Firmenbroschüre, Ihren Flyer, Ihre
        Website oder Ihren Jahresbericht. Oder brauchen Sie ein neues Corporate
        Design? Wir bringen Ihr Logo, Ihre Philosophie und ihre Firmenziele auf
        einen Nenner und machen Sie mit einem einzigartigen visuellen Auftritt
        zu einem erfolgreichen Marktteilnehmer.
      </p>
    </div>
  );
};

export default Teaser;
