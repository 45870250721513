import React from "react";

import Banner from "./home/banner";

const siteDatenschutz = ({ mediaQueries}) => {
  return (
    <main>
      <Banner className="main__banner" mediaQueries={mediaQueries} />

      <div className="main__datenschutz">

      <h1 id="top">Datenschutzerklärung</h1>

      <p>
        Mit dieser <strong>Datenschutzerklärung</strong> informieren wir über
        die Bearbeitung von Personendaten im Zusammenhang mit unseren{" "}
        <strong>Aktivitäten und Tätigkeiten </strong> einschliesslich unserer{" "}
        <strong>
          <nobr>www.tom-ifd.ch</nobr>-Website
        </strong>
        . Wir informieren insbesondere, wofür, wie und wo wir welche
        Personendaten bearbeiten. Wir informieren ausserdem über die Rechte von
        Personen, deren Daten wir bearbeiten.
      </p>

      <p>
        Für einzelne oder zusätzliche Aktivitäten und Tätigkeiten können weitere
        Datenschutzerklärungen oder sonstige Informationen zum Datenschutz
        gelten.
      </p>

      <h2 id="kontaktadressen">1. Kontaktadressen</h2>

      <p>Verantwortung für die Bearbeitung von Personendaten:</p>

      <p>
        <strong id="verantwortlicher">Tom Schneider</strong>
        <br />
        Tom – it's fair design!
        <br />
        Landskronweg 26
        <br />
        4107 Ettingen
      </p>

      <p>
        <a href="mailto:mail@tom-ifd.ch">mail@tom-ifd.ch</a>
      </p>

      <p>
        Im Einzelfall können Dritte für die Bearbeitung von Personendaten
        verantwortlich sein oder es kann eine gemeinsame Verantwortlichkeit mit
        Dritten bestehen.
      </p>

      <h2 id="begriffe-rechtsgrundlagen">2. Begriffe und Rechtsgrundlagen</h2>

      <h3 id="begriffe">2.1 Begriffe</h3>

      <p>
        <strong>Betroffene Person:</strong> Natürliche Person, über die wir
        Personendaten bearbeiten.
      </p>

      <p>
        <strong>Personendaten:</strong> <em>Alle</em> Angaben, die sich auf eine
        bestimmte oder bestimmbare natürliche Person beziehen.
      </p>

      <p>
        <strong>Besonders schützenswerte Personendaten:</strong> Daten über
        gewerkschaftliche, politische, religiöse oder weltanschauliche Ansichten
        und Tätigkeiten, Daten über die Gesundheit, die Intimsphäre oder die
        Zugehörigkeit zu einer Ethnie oder Rasse, genetische Daten, biometrische
        Daten, die eine natürliche Person eindeutig identifizieren, Daten über
        straf- und verwaltungsrechtliche Sanktionen oder Verfolgungen, und Daten
        über Massnahmen der sozialen Hilfe.
      </p>

      <p>
        <strong>Bearbeiten:</strong> <em>Jeden</em> Umgang mit Personendaten,{" "}
        <em>unabhängig</em> von den angewandten Mitteln und Verfahren,
        beispielsweise das Abfragen, Abgleichen, Anpassen, Archivieren,
        Aufbewahren, Auslesen, Bekanntgeben, Beschaffen, Erfassen, Erheben,
        Löschen, Offenlegen, Ordnen, Organisieren, Speichern, Verändern,
        Verbreiten, Verknüpfen, Vernichten und Verwenden von Personendaten.
      </p>

      <h3 id="rechtsgrundlagen">2.2 Rechtsgrundlagen</h3>

      <p>
        Wir bearbeiten Personendaten im Einklang mit dem schweizerischen
        Datenschutzrecht wie insbesondere dem{" "}
        <a
          href="https://www.fedlex.admin.ch/eli/oc/2022/491/de"
          rel="nofollow noopener noreferrer"
          target="_blank"
          metadata="158671-1724233460"
        >
          Bundesgesetz über den Datenschutz
        </a>{" "}
        (Datenschutzgesetz, DSG) und der{" "}
        <a
          href="https://www.fedlex.admin.ch/eli/oc/2022/568/de"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          Verordnung über den Datenschutz
        </a>{" "}
        (Datenschutzverordnung, DSV).
      </p>

      <h2 id="art-umfang-zweck">
        3. Art, Umfang und Zweck der Bearbeitung von Personendaten
      </h2>

      <p>
        Wir bearbeiten jene Personendaten, die <em>erforderlich</em> sind, um
        unsere Aktivitäten und Tätigkeiten dauerhaft, menschenfreundlich, sicher
        und zuverlässig ausüben zu können. Die bearbeiteten Personendaten können
        insbesondere in die Kategorien von Browser- und Gerätedaten,
        Inhaltsdaten, Kommunikationsdaten, Metadaten, Nutzungsdaten, Stammdaten
        einschliesslich Bestandes- und Kontaktdaten, Standortdaten,
        Transaktionsdaten, Vertragsdaten und Zahlungsdaten fallen.
      </p>

      <p>
        Wir bearbeiten auch Personendaten, die wir von Dritten erhalten, aus
        öffentlich zugänglichen Quellen beschaffen oder bei der Ausübung unserer
        Aktivitäten und Tätigkeiten erheben, soweit eine solche Bearbeitung aus
        rechtlichen Gründen zulässig ist.
      </p>

      <p>
        Wir bearbeiten Personendaten, soweit erforderlich, mit Einwilligung der
        betroffenen Personen. Wir können Personendaten in vielen Fällen ohne
        Einwilligung bearbeiten, beispielsweise um rechtliche Verpflichtungen zu
        erfüllen oder überwiegende Interessen zu wahren. Wir können betroffene
        Personen auch um ihre Einwilligung ersuchen, wenn ihre Einwilligung
        nicht erforderlich ist.
      </p>

      <p>
        Wir bearbeiten Personendaten für die <em>Dauer</em>, die für den
        jeweiligen Zweck erforderlich ist. Wir anonymisieren bzw. löschen
        Personendaten insbesondere in Abhängigkeit von gesetzlichen
        Aufbewahrungs- und Verjährungsfristen.
      </p>

      <h2 id="bekanntgabe">4. Bekanntgabe von Personendaten</h2>

      <p>
        Wir können Personendaten <em>an Dritte bekanntgeben</em>, durch Dritte
        bearbeiten lassen oder gemeinsam mit Dritten bearbeiten. Bei solchen
        Dritten handelt es sich insbesondere um spezialisierte Anbieter, deren
        Leistungen wir in Anspruch nehmen.
      </p>

      <p>
        Wir können Personendaten beispielsweise an Banken und andere
        Finanzdienstleister, Behörden, Bildungs- und Forschungseinrichtungen,
        Berater und Rechtsanwälte, Interessenvertretungen, IT-Dienstleister,
        Kooperationspartner, Kredit- und Wirtschaftsauskunfteien, Logistik- und
        Versandunternehmen, Marketing- und Werbeagenturen, Medien,
        Organisationen und Verbände, soziale Einrichtungen,
        Telekommunikationsunternehmen und Versicherungen bekanntgeben.
      </p>

      <h2 id="kommunikation">5. Kommunikation</h2>

      <p>
        Wir bearbeiten Personendaten, um mit Dritten kommunizieren zu können.
        Wir bearbeiten in diesem Rahmen insbesondere Daten, die eine betroffene
        Person bei der Kontaktaufnahme übermittelt, zum Beispiel per Briefpost
        oder E-Mail. Wir können solche Daten in einem Adressbuch oder mit
        vergleichbaren Hilfsmitteln speichern.
      </p>

      <p>
        Dritte, die Daten über andere Personen übermitteln, sind verpflichtet,
        den Datenschutz gegenüber solchen betroffenen Personen zu gewährleisten.
        Dafür muss unter anderem die Richtigkeit der übermittelten Personendaten
        sichergestellt werden.
      </p>

      <h2 id="sicherheit">6. Datensicherheit</h2>

      <p>
        Wir treffen geeignete technische und organisatorische Massnahmen, um
        eine dem jeweiligen Risiko angemessene Datensicherheit zu gewährleisten.
        Mit unseren Massnahmen gewährleisten wir insbesondere die
        Vertraulichkeit, Verfügbarkeit, Nachvollziehbarkeit und Integrität der
        bearbeiteten Personendaten, ohne aber absolute Datensicherheit
        gewährleisten zu können.
      </p>

      <p>
        Der Zugriff auf unsere Website und unsere sonstige Online-Präsenz
        erfolgt mittels Transportverschlüsselung (
        <a
          href="https://de.wikipedia.org/wiki/Transport_Layer_Security"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          SSL&nbsp;/ TLS
        </a>
        , insbesondere mit dem{" "}
        <a
          href="https://de.wikipedia.org/wiki/Hypertext_Transfer_Protocol_Secure"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          Hypertext Transfer Protocol Secure, abgekürzt HTTPS
        </a>
        ). Die meisten Browser warnen vor dem Besuch von Websites ohne
        Transportverschlüsselung.
      </p>

      <p>
        Unsere digitale Kommunikation unterliegt&nbsp;– wie{" "}
        <em>grundsätzlich</em> jede digitale Kommunikation&nbsp;– der
        Massenüberwachung ohne Anlass und Verdacht durch Sicherheitsbehörden in
        der Schweiz, im übrigen Europa, in den Vereinigten Staaten von
        Amerika&nbsp;(USA) und in anderen Ländern. Wir können keinen direkten
        Einfluss auf die entsprechende Bearbeitung von Personendaten durch
        Geheimdienste, Polizeistellen und andere Sicherheitsbehörden nehmen. Wir
        können auch nicht ausschliessen, dass eine betroffene Personen gezielt
        überwacht wird.
      </p>

      <h2 id="ausland">7. Personendaten im Ausland</h2>

      <p>
        Wir bearbeiten Personendaten <em>grundsätzlich</em> in der Schweiz. Wir
        können Personendaten aber auch in andere Staaten bekanntgeben bzw.
        exportieren, insbesondere um sie dort zu bearbeiten oder bearbeiten zu
        lassen.
      </p>

      <p>
        Wir können Personendaten in alle{" "}
        <a
          href="https://de.wikipedia.org/wiki/Liste_der_Staaten_der_Erde#Liste"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          Staaten und Territorien auf der Erde
        </a>{" "}
        bekanntgeben, sofern das dortige Recht gemäss{" "}
        <a
          href="https://www.fedlex.admin.ch/eli/cc/2022/568/de#annex_1"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          Beschluss des Schweizerischen Bundesrates
        </a>{" "}
        einen angemessenen Datenschutz gewährleistet.
      </p>

      <p>
        Wir können Personendaten in Staaten, deren Recht keinen angemessenen
        Datenschutz gewährleistet, bekanntgeben, sofern aus anderen Gründen ein
        geeigneter Datenschutz gewährleistet ist, insbesondere auf Grundlage von
        Standarddatenschutzklauseln oder mit anderen geeigneten Garantien.
        Ausnahmsweise können wir Personendaten in Staaten ohne angemessenen oder
        geeigneten Datenschutz exportieren, wenn dafür die besonderen
        datenschutzrechtlichen Voraussetzungen erfüllt sind, beispielsweise die
        ausdrückliche Einwilligung der betroffenen Personen oder ein
        unmittelbarer Zusammenhang mit dem Abschluss oder der Abwicklung eines
        Vertrages. Wir geben betroffenen Personen auf Nachfrage gerne Auskunft
        über allfällige Garantien oder liefern eine Kopie von Garantien.
      </p>

      <h2 id="rechte">8. Rechte von betroffenen Personen</h2>

      <h3 id="ansprueche">8.1 Datenschutzrechtliche Ansprüche</h3>

      <p>
        Wir gewähren betroffenen Personen sämtliche Ansprüche gemäss dem
        anwendbaren Datenschutzrecht. Betroffene Personen verfügen insbesondere
        über folgende Rechte:
      </p>

      <ul>
        <li>
          <strong>Auskunft:</strong> Betroffene Personen können Auskunft
          verlangen, ob wir Personendaten über sie bearbeiten, und falls ja, um
          welche Personendaten es sich handelt. Betroffene Personen erhalten
          ferner jene Informationen, die erforderlich sind, um ihre
          datenschutzrechtlichen Ansprüche geltend zu machen und Transparenz zu
          gewährleisten. Dazu zählen die bearbeiteten Personendaten als solche,
          aber unter anderem auch Angaben zum Bearbeitungszweck, zur Dauer der
          Aufbewahrung, zu einer allfälligen Bekanntgabe bzw. einem allfälligen
          Export von Daten in andere Staaten und zur Herkunft der Personendaten.
        </li>
        <li>
          <strong>Berichtigung und Einschränkung:</strong> Betroffene Personen
          können unrichtige Personendaten berichtigen, unvollständige Daten
          vervollständigen und die Bearbeitung ihrer Daten einschränken lassen.
        </li>
        <li>
          <strong>Löschung und Widerspruch:</strong> Betroffene Personen können
          Personendaten löschen lassen («Recht auf Vergessen») und der
          Bearbeitung ihrer Daten mit Wirkung für die Zukunft widersprechen.
        </li>
        <li>
          <strong>Datenherausgabe und Datenübertragung:</strong> Betroffene
          Personen können die Herausgabe von Personendaten oder die Übertragung
          ihrer Daten an einen anderen Verantwortlichen verlangen.
        </li>
      </ul>

      <p>
        Wir können die Ausübung der Rechte von betroffenen Personen im rechtlich
        zulässigen Rahmen aufschieben, einschränken oder verweigern. Wir können
        betroffene Personen auf allenfalls zu erfüllende Voraussetzungen für die
        Ausübung ihrer datenschutzrechtlichen Ansprüche hinweisen. Wir können
        beispielsweise die Auskunft mit Verweis auf Geschäftsgeheimnisse oder
        den Schutz anderer Personen ganz oder teilweise verweigern. Wir können
        beispielsweise auch die Löschung von Personendaten mit Verweis auf
        gesetzliche Aufbewahrungspflichten ganz oder teilweise verweigern.
      </p>

      <p>
        Wir können für die Ausübung der Rechte <em>ausnahmsweise</em> Kosten
        vorsehen. Wir informieren betroffene Personen vorgängig über allfällige
        Kosten.
      </p>

      <p>
        Wir sind verpflichtet, betroffene Personen, die Auskunft verlangen oder
        andere Rechte geltend machen, mit angemessenen Massnahmen zu
        identifizieren. Betroffene Personen sind zur Mitwirkung verpflichtet.
      </p>

      <h3 id="rechtsschutz">8.2 Rechtsschutz</h3>

      <p>
        Betroffene Personen haben das Recht, ihre datenschutzrechtlichen
        Ansprüche auf dem Rechtsweg durchzusetzen oder Anzeige bzw. Beschwerde
        bei einer Datenschutz-Aufsichtsbehörde zu erheben.
      </p>

      <p>
        Datenschutz-Aufsichtsbehörde für private Verantwortliche und
        Bundesorgane in der Schweiz ist der{" "}
        <a
          href="https://www.edoeb.admin.ch/"
          rel="nofollow noopener noreferrer"
          target="_blank"
          metadata="158671-1724233460"
        >
          Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte
        </a>
        &nbsp;(EDÖB).
      </p>

      <h2 id="website">9. Nutzung der Website</h2>

      <h3 id="cookies">9.1 Cookies</h3>

      <p>
        Wir können Cookies verwenden. Bei Cookies&nbsp;– eigenen Cookies
        (First-Party-Cookies) als auch Cookies von Dritten, deren Dienste wir
        nutzen (Third-Party-Cookies)&nbsp;– handelt es sich um Daten, die im
        Browser gespeichert werden. Solche gespeicherten Daten müssen nicht auf
        traditionelle Cookies in Textform beschränkt sein.
      </p>

      <p>
        Cookies können im Browser temporär als «Session Cookies» oder für einen
        bestimmten Zeitraum als sogenannte permanente Cookies gespeichert
        werden. «Session Cookies» werden automatisch gelöscht, wenn der Browser
        geschlossen wird. Permanente Cookies haben eine bestimmte Speicherdauer.
        Cookies ermöglichen insbesondere, einen Browser beim nächsten Besuch
        unserer Website wiederzuerkennen und dadurch beispielsweise die
        Reichweite unserer Website zu messen. Permanente Cookies können aber
        beispielsweise auch für Online-Marketing verwendet werden.
      </p>

      <p>
        Cookies können in den Browser-Einstellungen jederzeit ganz oder
        teilweise deaktiviert sowie gelöscht werden. Ohne Cookies steht unsere
        Website allenfalls nicht mehr in vollem Umfang zur Verfügung. Wir
        ersuchen&nbsp;– mindestens sofern und soweit erforderlich&nbsp;– aktiv
        um die ausdrückliche Einwilligung in die Verwendung von Cookies.
      </p>

      <h3 id="protokollierung">9.2 Protokollierung</h3>

      <p>
        Wir können für jeden Zugriff auf unsere Website und unsere sonstige
        Online-Präsenz mindestens nachfolgende Angaben protokollieren, sofern
        diese bei solchen Zugriffen an unsere digitale Infrastruktur übermittelt
        werden: Datum und Zeit einschliesslich Zeitzone,{" "}
        <a
          href="https://de.wikipedia.org/wiki/IP-Adresse"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          IP-Adresse
        </a>
        , Zugriffsstatus (HTTP-Statuscode), Betriebssystem einschliesslich
        Benutzeroberfläche und Version, Browser einschliesslich Sprache und
        Version, aufgerufene einzelne Unter-Seite unserer Website
        einschliesslich übertragener Datenmenge, zuletzt im gleichen
        Browser-Fenster aufgerufene Webseite (Referer bzw. Referrer).
      </p>

      <p>
        Wir protokollieren solche Angaben, die auch Personendaten darstellen
        können, in Logdateien. Die Angaben sind erforderlich, um unsere
        Online-Präsenz dauerhaft, menschenfreundlich und zuverlässig
        bereitstellen zu können. Die Angaben sind ferner erforderlich, um die
        Datensicherheit gewährleisten zu können&nbsp;– auch durch Dritte oder
        mit Hilfe von Dritten.
      </p>

      <h3 id="zaehlpixel">9.3 Zählpixel</h3>

      <p>
        Wir können Zählpixel in unsere Online-Präsenz einbinden. Zählpixel
        werden auch als Web-Beacons bezeichnet. Bei Zählpixeln&nbsp;– auch von
        Dritten, deren Dienste wir nutzen&nbsp;– handelt es sich üblicherweise
        um kleine, nicht sichtbare Bilder oder in JavaScript formulierte
        Skripte, die beim Zugriff auf unsere Online-Präsenz automatisch
        abgerufen werden. Mit Zählpixeln können mindestens die gleichen Angaben
        wie in Logdateien erfasst werden.
      </p>

      <h2 id="social-media">10. Social Media</h2>

      <p>
        Wir sind auf Social Media-Plattformen und anderen Online-Plattformen
        präsent, um mit interessierten Personen kommunizieren sowie über unsere
        Aktivitäten und Tätigkeiten informieren zu können. Im Zusammenhang mit
        solchen Plattformen können Personendaten auch ausserhalb der Schweiz
        bearbeitet werden.
      </p>

      <p>
        Es gelten jeweils auch die Allgemeinen Geschäftsbedingungen (AGB) und
        Nutzungsbedingungen sowie Datenschutzerklärungen und sonstigen
        Bestimmungen der einzelnen Betreiber solcher Plattformen. Diese
        Bestimmungen informieren insbesondere über die Rechte von betroffenen
        Personen direkt gegenüber der jeweiligen Plattform, wozu beispielsweise
        das Recht auf Auskunft zählt.
      </p>

      <h2 id="dienste">11. Dienste von Dritten</h2>

      <p>
        Wir nutzen Dienste von spezialisierten Dritten, um unsere Aktivitäten
        und Tätigkeiten dauerhaft, menschenfreundlich, sicher und zuverlässig
        ausüben zu können. Mit solchen Diensten können wir unter anderem
        Funktionen und Inhalte in unsere Website einbetten. Bei einer solchen
        Einbettung erfassen die genutzten Dienste aus technisch zwingenden
        Gründen mindestens zeitweilig die{" "}
        <a
          href="https://de.wikipedia.org/wiki/IP-Adresse"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          IP-Adressen
        </a>{" "}
        der Nutzerinnen und Nutzer.
      </p>

      <p>
        Für erforderliche sicherheitsrelevante, statistische und technische
        Zwecke können Dritte, deren Dienste wir nutzen, Daten im Zusammenhang
        mit unseren Aktivitäten und Tätigkeiten aggregiert, anonymisiert oder
        pseudonymisiert bearbeiten. Es handelt sich beispielsweise um Leistungs-
        oder Nutzungsdaten, um den jeweiligen Dienst anbieten zu können.
      </p>

      <p>Wir nutzen insbesondere:</p>

      <ul>
        <li>
          <strong>
            <a
              href="https://about.google/products/?hl=de"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Dienste von Google:
            </a>
          </strong>{" "}
          Anbieterinnen: Google&nbsp;LLC (USA)&nbsp;/ Google Ireland Limited
          (Irland) teilweise für Nutzerinnen und Nutzer im Europäischen
          Wirtschaftsraum&nbsp;(EWR) und in der Schweiz; Allgemeine Angaben zum
          Datenschutz:{" "}
          <a
            href="https://safety.google/intl/de/principles/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Grundsätze zu Datenschutz und Sicherheit»
          </a>
          ,{" "}
          <a
            href="https://business.safety.google/privacy/?hl=de"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Informationen, wie Google personenbezogene Daten verwendet»
          </a>
          ,{" "}
          <a
            href="https://policies.google.com/privacy?hl=de"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Datenschutzerklärung
          </a>
          ,{" "}
          <a
            href="https://business.safety.google/intl/de/compliance/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Google ist der Einhaltung der anwendbaren Datenschutzgesetze
            verpflichtet»
          </a>
          ,{" "}
          <a
            href="https://policies.google.com/technologies/product-privacy?hl=de"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Leitfaden zum Datenschutz in Google-Produkten»
          </a>
          ,{" "}
          <a
            href="https://policies.google.com/technologies/partner-sites?hl=de"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Wie wir Daten von Websites oder Apps verwenden, auf bzw. in denen
            unsere Dienste genutzt werden»
          </a>
          ,{" "}
          <a
            href="https://policies.google.com/technologies/cookies?hl=de"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Arten von Cookies und ähnliche Technologien, die Google verwendet»
          </a>
          ,{" "}
          <a
            href="https://myadcenter.google.com/?hl=de"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Werbung, auf die du Einfluss hast» («Personalisierte Werbung»)
          </a>
          .
        </li>
        <li>
          <strong>
            <a
              href="https://www.microsoft.com/de-ch"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Dienste von Microsoft:
            </a>
          </strong>{" "}
          Anbieterinnen: Microsoft Ireland Operations Limited (Irland) für
          Nutzerinnen und Nutzer im Europäischen Wirtschaftsraum&nbsp;(EWR), in
          der Schweiz und im Verereinigten Königreich&nbsp;/ Microsoft
          Corporation&nbsp;(USA) für Nutzerinnen und Nutzer im Rest der Welt;
          Allgemeine Angaben zum Datenschutz:{" "}
          <a
            href="https://privacy.microsoft.com/de-de"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Datenschutz bei Microsoft»
          </a>
          ,{" "}
          <a
            href="https://www.microsoft.com/de-ch/trust-center/privacy"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Datenschutz und Privatsphäre»
          </a>
          ,{" "}
          <a
            href="https://privacy.microsoft.com/de-de/privacystatement"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Datenschutzerklärung
          </a>
          ,{" "}
          <a
            href="https://account.microsoft.com/account/privacy?lang=de-DE"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Daten- und Datenschutzeinstellungen»
          </a>
          .
        </li>
      </ul>

      <h3 id="infrastruktur">11.1 Digitale Infrastruktur</h3>

      <p>
        Wir nutzen Dienste von spezialisierten Dritten, um benötigte digitale
        Infrastruktur im Zusammenhang mit unseren Aktivitäten und Tätigkeiten in
        Anspruch nehmen zu können. Dazu zählen beispielsweise Hosting- und
        Speicherdienste von ausgewählten Anbietern.
      </p>

      <p>Wir nutzen insbesondere:</p>

      <ul>
        <li>
          <strong>
            <a
              href="https://www.hostpoint.ch/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Hostpoint:
            </a>
          </strong>{" "}
          Hosting; Anbieterin: Hostpoint&nbsp;AG (Schweiz); Angaben zum
          Datenschutz:{" "}
          <a
            href="https://www.hostpoint.ch/hostpoint/kontakt-agb.html#datenschutz"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Datenschutzerklärung
          </a>
          .
        </li>
      </ul>

      <h3 id="videokonferenz">11.2 Audio- und Video-Konferenzen</h3>

      <p>
        Wir nutzen spezialisierte Dienste für Audio- und Video-Konferenzen, um
        online kommunizieren zu können. Wir können damit beispielsweise
        virtuelle Besprechungen abhalten oder Online-Unterricht und Webinare
        durchführen. Für die Teilnahme an Audio- und Video-Konferenzen gelten
        ergänzend die Rechtstexte der einzelnen Dienste wie
        Datenschutzerklärungen und Nutzungsbedingungen.
      </p>

      <p>
        Wir empfehlen, je nach Lebenssituation bei der Teilnahme an Audio- oder
        Video-Konferenzen das Mikrofon standardmässig stumm zu schalten sowie
        den Hintergrund unscharf zu stellen oder einen virtuellen Hintergrund
        einblenden zu lassen.
      </p>

      <p>Wir nutzen insbesondere:</p>

      <ul>
        <li>
          <strong>
            <a
              href="https://www.skype.com/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Skype:
            </a>
          </strong>{" "}
          Audio- und Video-Konferenzen; Anbieterin: Microsoft; Skype-spezifische
          Angaben zum Datenschutz:{" "}
          <a
            href="https://www.skype.com/legal/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Rechtliches zu Skype»
          </a>
          .
        </li>
        <li>
          <strong>
            <a
              href="https://zoom.us/de"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Zoom:
            </a>
          </strong>{" "}
          Plattform für kollaboratives Arbeiten, insbesondere mit
          Video-Konferenzen; Anbieterin: Zoom Video Communications&nbsp;Inc.
          (USA); Angaben zum Datenschutz:{" "}
          <a
            href="https://explore.zoom.us/de/trust/privacy/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Datenschutz bei Zoom»
          </a>
          ,{" "}
          <a
            href="https://explore.zoom.us/de/privacy/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Datenschutzerklärung
          </a>
          ,{" "}
          <a
            href="https://explore.zoom.us/de/trust/legal-compliance/"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Rechtskonformität»
          </a>
          .
        </li>
      </ul>

      <h3 id="schriftarten">11.3 Schriftarten</h3>

      <p>
        Wir nutzen Dienste von Dritten, um ausgewählte Schriftarten sowie Icons,
        Logos und Symbole in unsere Website einbetten zu können.
      </p>

      <p>Wir nutzen insbesondere:</p>

      <ul>
        <li>
          <strong>
            <a
              href="https://fonts.adobe.com/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Adobe Fonts:
            </a>
          </strong>{" "}
          Schriftarten; Anbieterinnen: Adobe&nbsp;Inc. (USA) für Nutzerinnen und
          Nutzer in Nordamerika&nbsp;/ Adobe Systems Software Ireland Limited
          (Irland) für Nutzerinnen und Nutzer im Rest der Welt; Angaben zum
          Datenschutz:{" "}
          <a
            href="https://www.adobe.com/de/privacy.html"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Datenschutzzentrum von Adobe»
          </a>
          ,{" "}
          <a
            href="https://www.adobe.com/ch_de/privacy/policies/adobe-fonts.html"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Datenschutzerklärung (Adobe Fonts)
          </a>
          ,{" "}
          <a
            href="https://www.adobe.com/ch_de/privacy/policy.html"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Datenschutzerklärung (Adobe)
          </a>
          ,{" "}
          <a
            href="https://www.adobe.com/ch_de/privacy/privacy-contact.html"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Fragen zum Datenschutz?»
          </a>
          ,{" "}
          <a
            href="https://www.adobe.com/ch_de/privacy/opt-out.html"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Adobe-Datenschutzeinstellungen»
          </a>
          .
        </li>
        <li>
          <strong>
            <a
              href="https://fonts.google.com"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Google Fonts:
            </a>
          </strong>{" "}
          Schriftarten; Anbieterin: Google; Google Fonts-spezifische Angaben:{" "}
          <a
            href="https://fonts.googleblog.com/2022/11/your-privacy-and-google-fonts.html"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Ihre Privatsphäre und Google Fonts» («Your Privacy and Google
            Fonts»)
          </a>
          ,{" "}
          <a
            href="https://developers.google.com/fonts/faq/privacy?hl=de"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            «Datenschutz und Datenerfassung» (bei Google Fonts)
          </a>
          .
        </li>
      </ul>

      <h2 id="schlussbestimmungen">12. Schlussbestimmungen</h2>

      <p>
        Wir haben diese Datenschutzerklärung mit dem{" "}
        <a
          href="https://www.datenschutzpartner.ch/angebot-datenschutz-generator/"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          Datenschutz-Generator
        </a>{" "}
        von{" "}
        <a
          href="https://datenschutzpartner.ch/"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          Datenschutzpartner
        </a>{" "}
        erstellt.
      </p>

      <p>
        Wir können diese Datenschutzerklärung jederzeit anpassen und ergänzen.
        Wir werden über solche Anpassungen und Ergänzungen in geeigneter Form
        informieren, insbesondere durch Veröffentlichung der jeweils aktuellen
        Datenschutzerklärung auf unserer Website.
      </p>
      
      </div>
    </main>
  );
};

export default siteDatenschutz;
