import React from "react";

import imgHintergrundBuero from "../../assets/hintergrund-buerotisch.jpg";
import imgHintergrundBueroGross from "../../assets/hintergrund-buerotisch-gross.jpg";

const Banner = ({ className, mediaQueries }) => {
  const imgHintergrund =
    mediaQueries.size === "small"
      ? imgHintergrundBuero
      : imgHintergrundBueroGross;

  return (
    <React.Fragment>
      <div
        className={className}
        style={{ backgroundImage: `url(${imgHintergrund})` }}
      >
        <TitleText mediaQueries={mediaQueries} />
      </div>
    </React.Fragment>
  );
};

const TitleText = ({ mediaQueries }) => {
  let titleText =
    mediaQueries.size === "small"
      ? "Grafik & Webdesign im Raum Basel."
      : "Atelier für Grafik & Webdesign im Raum Basel. Hier erwarten Sie Top-Leistungen verbunden mit rücksichtsvollem Arbeiten!";

  return <h2>{titleText}</h2>;
};

export default Banner;
