import React from "react";


const Address = ({ mediaQueries, address, className }) => {
  const addressLine =
    mediaQueries.size === "small" || mediaQueries.size === "medium" ? (
      <React.Fragment>
   
        <ul>
          <li>{address.name}</li>
          <li>{address.street}</li>
          <li>{address.city}</li>
        </ul>
        <div className="address--links">
          <div>
            <div className="button button--link">
              <a href="mailto:mail@tom-ifd.ch">{address.mail}</a>
            </div>
          </div>
          <div>
            <div className="button button--link">
              <a href="tel:+41617312226">{address.tel}</a>
            </div>
          </div>
        </div>
      </React.Fragment>
    ) : (
      <div>
        <p>
          {address.name}, {address.street}, {address.city}
        </p>
        <div className="button button--link">
          <a href="mailto:mail@tom-ifd.ch">{address.mail}</a>
        </div>
        <div className="button button--link">
          <a href="tel:+41617312226">{address.tel}</a>
        </div>
      </div>
    );

  return <div className={className}>{addressLine}</div>;
};

export default Address;
