const getApiData = async (state, site) => {
  const apiKey = state.api.key;
  const apiRootUrl = state.api.rootUrl;
  const apiPath = state.api.apiPath;

  const apiUrl = `${apiRootUrl}${apiPath}`;

  let newState = state;

  /*   if (site === "home") {
    // Adresse holen

    try {
      let response = await fetch(`${apiUrl}singletons/get/address`, {
        headers: {
          Authorization: apiKey,
        },
      });
      let fetchAddress = await response.json();

      newState.address.name = fetchAddress.name;
      newState.address.street = fetchAddress.strasse;
      newState.address.city = fetchAddress.plzort;
      newState.address.mail = fetchAddress.mail;
      newState.address.tel = fetchAddress.tel; 
    } catch (error) {
      console.log(
        "Fehler Adresse: Das CMS ist zur Zeit leider nicht verfügbar. Bitte Seite später nochmals neu laden"
      );
    }
  } */

  //Projekte holen

  try {
    let response = await fetch(`${apiUrl}collections/get/projects`, {
      headers: {
        Authorization: apiKey,
      },
    });
    let fetchProjects = await response.json();

    fetchProjects = fetchProjects.entries;

    fetchProjects.forEach((project) => {
      newState.projects.push({
        name: project.name,
        text: project.description,
        gallery: project.gallery,
        coverImage: project.coverimage,
      });
    });
  } catch (error) {
    console.log(
      "Fehler: Das CMS ist zur Zeit leider nicht verfügbar. Bitte Seite später nochmals neu laden"
    );
  }

  return newState;
};

export default getApiData;
