import React from "react";
import imgIconClose from "../../assets/close-icon.svg";
import imgStoerer from "../../assets/thatsfairdesign.svg";
import { useTransition, animated } from "react-spring"; // Import hooks and animated component

const StoererTeaser = ({ className, handleShowStoerer, stoererShow }) => {
  // Use the `useTransition` hook to manage the visibility and animation of the components
  const transitions = useTransition(stoererShow, {
    from: {
      opacity: 0,
      hoverOpacity: 0,
      hoverSize: 110,
      hoverTop: 380,
      hoverLeft: "30%",
    },
    enter: {
      opacity: 1,
      hoverOpacity: 1,
      hoverSize: 350,
      hoverTop: 145,
      hoverLeft: "0%",
    },
    leave: {
      opacity: 0,
      hoverOpacity: 0,
      hoverSize: 110,
      hoverTop: 380,
      hoverLeft: "30%",
    },
    config: { duration: 200 },
  });

  return (
    <div className={className}>
      {transitions((styles, item) =>
        item ? ( // If item is true (stoererShow is true), show the hover container
          <animated.div
            className="footer__stoerer--teaser-hover-container"
            style={{
              opacity: styles.opacity,
            }}
          >
            <animated.div
              className="footer__stoerer--teaser-hover"
              style={{
                top: styles.hoverTop,
                width: styles.hoverSize,
                height: styles.hoverSize,
                marginLeft: styles.hoverLeft,
                opacity: styles.hoverOpacity,
              }}
            >
              <div>
                <p>
                  Wir kochen auch <br /> mit Wasser - allerdings <br />
                  <span>klima- und umfeldbewusst.</span> <br /> Denn das
                  besondere Augenmerk unserers Unternehmens liegt in der
                  Steigerung der Rücksicht auf unsere Gesellschaft und Umwelt.
                  Wir arbeiten prioritär mit Partnern zusammen, welche
                  klimaneutral produzieren <br /> und/oder FSC-zertifiziert sind.
                </p>
                <img
                  src={imgIconClose}
                  alt="Schliessen Icon"
                  onClick={() => handleShowStoerer(false)}
                />
              </div>
            </animated.div>
          </animated.div>
        ) : (
          <animated.div
            style={{
              opacity: styles.opacity,
            }}
            onClick={() => handleShowStoerer(true)}
            className="footer__stoerer--teaser"
          >
            <img
              className="footer__stoerer"
              src={imgStoerer}
              alt="Störer mit Text: that's fair design!"
            />
          </animated.div>
        )
      )}
    </div>
  );
};

export default StoererTeaser;
