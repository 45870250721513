import React, { Component } from "react";

import imgDiamant from "../../assets/Diamant.svg";

class Projekte extends Component {
  state = {
    cards: [
      {
        title: "Corporate Design",
        content:
          "Visitenkarten, Briefpapier, Couverts, Präzismappen, Notizblöcke, Schreibgeräte, etc."
      },
      {
        title: "Printdesign",
        content:
          "Flyer, Broschüren, Publikationen, Jahresberichte, Packagingdesign, Einladungskarten, etc."
      },
      {
        title: "Webdesign",
        content:
          "kreative Lösungen mittels aktuellsten Technologien für unterschiedlichste Anforderungen"
      },
      {
        title: "Fotografie",
        content:
          "internes Profi-Studio für Kleinaufträge. Grössere Projekte realisieren wir extern zusammen mit Spezialisten"
      }
    ]
  };
  render() {
    return (
      <div className={this.props.className}>
        <h2>Spektrum</h2>
        <p>
          Wir bieten Konzeption, Kreation und Realisation im beinah gesamten
          Umfeld der visuellen Kommunikation. Unser Angebot umfasst Arbeiten in
          den Bereichen Corporate Design, Printdesign, Webdesign und Fotografie.
        </p>

        <div className="spektrum__vcards">
          {this.state.cards.map((card, index) => (
            <div key={index} className="spektrum__vcards--card">
              <img src={imgDiamant} alt="Diamant Piktorgram" />
              <h3>{card.title}</h3>
              <p>{card.content}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Projekte;
