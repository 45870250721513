import React from "react";
import ProjectCard from "./projectCard";

const Projekte = ({ className, clientData, rootUrl }) => {
  return (
    <div className={className}>
      <h2>Aktuelle Projekte</h2>
      <div className="projekte--liste">
        {clientData.slice(0, 6).map((card, index) => (
          <ProjectCard
            key={index}
            projectId={index}
            title={card.name}
            text={card.text}
            coverImage={card.coverImage}
            rootUrl={rootUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default Projekte;
