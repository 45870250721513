import React from "react";


import imgMailPiktogram from "../../assets/Mail.svg";
import imgMailPiktogramHover from "../../assets/Mail_Hover.svg";
import imgPhonePiktogram from "../../assets/Phone.svg";
import imgPhonePiktogramHover from "../../assets/Phone_Hover.svg";

const Kontakt = ({ className, mediaQueries }) => {
  return (
    <div className={className}>
      <div className="kontakt--text">
        <TitleText mediaQueries={mediaQueries} />
        <p>
          Nehmen Sie bitte Kontakt mit uns auf - wir freuen uns, Sie kennen zu
          lernen!
        </p>
      </div>
      <div className="kontakt--piktogramme">
        <a href="mailto:mail@tom-ifd.ch">
          <img
            src={imgMailPiktogram}
            alt="E-Mail Piktogram"
            onMouseOver={e => (e.target.src = imgMailPiktogramHover)}
            onMouseOut={e => (e.target.src = imgMailPiktogram)}
          />
        </a>
        <a href="tel:+41617312226">
          <img
            src={imgPhonePiktogram}
            alt="Telefon Piktogram"
            onMouseOver={e => (e.target.src = imgPhonePiktogramHover)}
            onMouseOut={e => (e.target.src = imgPhonePiktogram)}
          />
        </a>
      </div>
    </div>
  );
};

const TitleText = ({ mediaQueries }) => {
  let titleText =
    mediaQueries.size === "small"
      ? "Interesse geweckt?"
      : "Haben wir Ihr Interesse geweckt?";

  return <h2>{titleText}</h2>;
};

export default Kontakt;
