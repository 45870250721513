import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTransition, animated } from "react-spring"; // Updated imports

const ProjectCard = ({ projectId, title, text, coverImage, rootUrl }) => {
  const [hover, setHover] = useState(false); // Replacing state management with useState

  const imageAlt = coverImage.meta ? coverImage.meta.title : "Titelbild";

  // Using `useTransition` to manage hover animations
  const transitions = useTransition(hover, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 300 },
  });

  return (
    <Link
      to={{
        pathname: "/projekte",
        projectId: projectId,
      }}
    >
      <div
        className="projekte--karte"
        onMouseEnter={() => setHover(true)} // Toggle hover state on mouse enter
        onMouseLeave={() => setHover(false)} // Toggle hover state on mouse leave
      >
        <img src={`${rootUrl}${coverImage.path}`} alt={imageAlt} />

        {transitions((style, item) =>
          item ? (
            <animated.div
              className="karte--layer-black"
              style={style} // Apply animated styles
            >
              <animated.div
                className="karte--layer-green"
                style={style} // Apply animated styles
              >
                <div className="karte--content">
                  <h3>{title}</h3>
                  <p>{text}</p>
                </div>
              </animated.div>
            </animated.div>
          ) : null
        )}
      </div>
    </Link>
  );
};

export default ProjectCard;
