import React from "react";

import imgHintergrundKlein from "../../assets/hintergrund-strategie.jpg";
import imgHintergrundGross from "../../assets/hintergrund-strategie-gross.jpg";
import imgPiktogram from "../../assets/strategie.svg";

const Strategie = ({ className, mediaQueries }) => {
  const backgroundStyle =
    mediaQueries.size === "small"
      ? {
          backgroundImage: `url(${imgHintergrundKlein})`
        }
      : {
          backgroundImage: `url(${imgHintergrundGross})`
        };

  return (
    <div className={className} style={backgroundStyle}>
      <div>
        <img src={imgPiktogram} alt="Piktogram Strategie zum Erfolg" />
        <h2>Strategie zum Erfolg</h2>
        <p>
          Eine ausführliche Analyse der Kundenbedürfnisse, die individuelle
          Beratung und eine massgeschneiderte Konzeption bilden jeglichen
          Grundstein unserer Arbeit. Dabei arbeiten wir qualitativ auf höchstem
          Niveau und streben maximale Effizienz an. Daraus resultieren unsere
          kreativen und erfolgreichen Gestaltungslösungen für Sie!
        </p>
      </div>
    </div>
  );
};

export default Strategie;
