import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ProjectsNavigation from "./projects/projectsNavigation";
import ClientImages from "./projects/clientImages";
import imgHintergrundProjekte from "../assets/hintergrund-projekte.jpg";
import { useTransition, animated } from "react-spring"; // Update import to use hooks API

const SiteProjects = (props) => {
  const location = useLocation(); 
  const [currentClient, setCurrentClient] = useState(0);
  const [animationIndex, setAnimationIndex] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentProject = location.projectId ? location.projectId : 0; 
    setCurrentClient(currentProject);
  }, [location]);

  const handleChangeClient = (direction) => {
    let position = currentClient;

    if (direction === 1) {
      position = position === props.projects.length - 1 ? 0 : position + 1;
    } else if (direction === 0) {
      position = position === 0 ? props.projects.length - 1 : position - 1;
    }

    setCurrentClient(position);
    setAnimationIndex(position + 1);
  };

  // Define the useTransition hook for animating the client images
  const transitions = useTransition(animationIndex, {
    from: { opacity: 0, display: "none" },
    enter: { opacity: 1, display: "block" },
    leave: { opacity: 0, display: "none" },
    config: { duration: 700 },
  });

  // Wrap the ClientImages component in the animated component from react-spring
  const AnimatedClientImages = animated(ClientImages);

  return (
    <main className={props.className}>
      <div
        className="projects__header"
        style={{ backgroundImage: `url(${imgHintergrundProjekte})` }}
      >
        <ProjectsNavigation
          clientData={props.projects[currentClient]}
          onChangeClient={handleChangeClient}
        />
      </div>
      {transitions((style, item) =>  // Use transitions to animate the ClientImages component
        item === animationIndex && ( // Ensure the correct item is shown
          <AnimatedClientImages
            className="projects__images"
            clientData={props.projects[currentClient]}
            style={style}
            rootUrl={props.rootUrl}
          />
        )
      )}
      <div className="projects__footer">
        <ProjectsNavigation
          clientData={props.projects[currentClient]}
          onChangeClient={handleChangeClient}
        />
      </div>
    </main>
  );
};

export default SiteProjects;
